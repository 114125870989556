import * as flexsearch from "flexsearch";
import { encode } from "flexsearch/src/lang/latin/simple";
import { createIdIndexMap } from "../../utils/idIndexMap";
import replaceHashPrefixInText from "../../utils/replaceHashPrefixInText";
import toString from "../../utils/toString";
import { createLogger } from "../../utils/log";
import { iterateNoteContents } from "./db";

const log = createLogger("searchDb");
const searchIndex = new flexsearch.Index({
  tokenize: "forward",
  // charset: null,
  encode: encode,
  optimize: true,
  resolution: 1,
  minlength: 3,
  threshold: 0,
  fastupdate: false,
  context: false,
});

const idMap = createIdIndexMap();

export function search(searchText, limit = 100, ...args) {
  searchText = replaceHashPrefixInText(searchText, false);

  return searchIndex
    .search(searchText, limit, ...args)
    .map(idMap.getIdForIndex);
}

export function set(id, value) {
  value = replaceHashPrefixInText(value, true);
  const idIndex = idMap.addToIndex(id);
  searchIndex.add(idIndex, value);
}

export function remove(id) {
  const index = idMap.getIndexForId(id);
  searchIndex.remove(index);
  idMap.removeById(id);
}

let searchIndexInitialised = false;
export async function initialiseSearchIndex(notes) {
  let start = Date.now();
  if (searchIndexInitialised) return;
  searchIndexInitialised = true;
  log.info("indexing search");

  await iterateNoteContents((content, key) => {
    try {
      const note = notes[key];
      const additionalTags = [];
      if (note && !note.deleted) {
        if (note.shareId) {
          additionalTags.push("#shared");
        }
        if (Object.keys(note.files).length > 1) {
          additionalTags.push("#attachments");
        }
        const suffix = ` ${additionalTags.join(" ")}`;

        set(key, toString(content.data) + suffix);
      }
    } catch (err) {
      log.error("search for note failed", key, err);
    }
  });

  let end = Date.now();

  log.info(
    `Finished indexing search. time taken ${(end - start) / 1000} seconds`
  );
}
window.search = search;
