import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import CodeBlock from "./previewComponents/CodeBlock";
import Prism from "prism-react-renderer/prism";
import { ErrorBoundary } from "./ErrorBoundary";
import Image from "./previewComponents/Image";
import PreviewContextProvider from "./PreviewContextProvider";
import Video from "./previewComponents/Video";
import { useEffect, useMemo, useState } from "react";
import { createLogger } from "../../../../utils/log";
import { useLogUnmount } from "../../../../hooks/useLogUnmount";
import React from "react";
import Pdf from "./previewComponents/Pdf";
import { VFile } from "vfile";
import { evaluate } from "@mdx-js/mdx";
import * as runtime from "react/jsx-runtime";

const log = createLogger("notePreview");
(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-csharp");
require("prismjs/components/prism-java");

const Wrapper = styled.div`
  width: 100%;
  height: auto;

  padding: 1rem;
  // Increase x padding so full screen content like excalidraw leaves room to scroll on mobile
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  //padding-bottom: 5rem;

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style: initial;
  }

  //https://www.html5canvastutorials.com/blog/2012/06/custom-form-radio-checkbox/
  input[type="checkbox"] {
    -webkit-appearance: none;
    color: red !important;
    background-color: transparent;
    border: 1px solid #505050;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 12px;
    border-radius: 3px;
    display: inline-block;
    position: relative;

    &:checked {
      //background-color: #e9ecee;

      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      //color: #99a1a7;
      //color: ${(p) => p.theme.colors.secondary[0]};
    }

    &:checked:after {
      content: "\\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-size: 12px;
      position: absolute;
      top: 3px;
      left: 6px;
      color: ${(p) => p.theme.colors.secondary[1]};

      &:active,
      &:checked:active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px 1px 3px rgba(0, 0, 0, 0.1);
      }
    }
  }

  color: ${(p) => p.theme.colors.text};
  ${styleSystem()}
`;

const components = {
  pre: (props) => <div {...props} className={"contents"} />,
  code: CodeBlock,
  Image: Image,
  Video: Video,
  Pdf: Pdf,
};

const NotePreview = ({ editable, note, content, ...props }) => {
  useLogUnmount(log, "note preview");

  if (!content) {
    return null;
  }

  return (
    <>
      <Wrapper {...props} className="note-preview scrollbar !h-full">
        <PreviewContextProvider
          editable={editable}
          note={note}
          content={content}
        >
          <MemoMdx content={content} />
        </PreviewContextProvider>
      </Wrapper>
    </>
  );
};

const MemoMdx = React.memo(({ content }) => {
  useLogUnmount(log, "MemoMdx");
  const [mdxContent, setMdxContent] = useState();
  // const expanded = useState(selectex);
  useEffect(() => {
    async function load() {
      console.log("lol content", content);
      const file = new VFile({ basename: "note.mdx", value: content });
      const result = (
        await evaluate(file, {
          ...runtime,
          useDynamicImport: true,
          useMDXComponents: () => components,
          development: false,
        })
      ).default;
      setMdxContent(result);
    }
    load();
  }, [content]);

  return (
    <>
      <Inner className="markdown-body !h-full">
        <ErrorBoundary content={content}>
          {mdxContent}
          {/*<MDXProvider components={components}></MDXProvider>*/}
        </ErrorBoundary>
      </Inner>
    </>
  );
});

// const NotePreview = React.memo(_NotePreview);

const Inner = styled.div`
  width: 100%;
  height: auto;
  // This will be so content looks good on shared notes viewed on ultrawide screens.
  // However the max width is crappy when using excalidraw
  //max-width: 1280px;
  margin: 0 auto;
`;
export default NotePreview;
