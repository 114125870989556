import React, { useEffect, useState } from "react";
import mermaid from "mermaid";
import styled from "styled-components";
import { Excalidraw as ExcalidrawExt } from "@excalidraw/excalidraw";
export default function Excalidraw({ children }) {
  const [state, setState] = useState();
  useEffect(() => {
    if (children) {
      const obj = JSON.parse(children);
      setState(obj);
      console.log("lol exc", obj);
    }
  }, [children]);
  if (!state) {
    return null;
  }
  return (
    <div className={"h-full flex items-stretch"}>
      <ExcalidrawExt
        className={"ffdsa"}
        viewModeEnabled={true}
        initialData={{ ...state, scrollToContent: true }}
      />
    </div>
  );
}
